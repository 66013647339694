import { getCollectionUrlAsPath } from 'Utils/collectionsUtils';
import { getLocalisedPrice } from 'Utils/currencyUtils';

import type { TCollection, TCollectionCardV2 } from 'ReduxTypes/collection';
import type { TCurrency } from 'ReduxTypes/generics';

import { GLOBAL_CITY_CODE } from 'Constants/constants';
import { strings } from 'Constants/strings';

export const getCollectionCardDetails = ({
	collectionDetails,
	lang,
	currentCityCode,
	appendCityCode,
	currenciesMap,
	isSearch,
}: {
	collectionDetails: TCollection;
	lang: string;
	currentCityCode: string;
	appendCityCode: boolean;
	currenciesMap: Record<string, TCurrency>;
	isSearch: boolean;
}) => {
	const {
		heroImageUrl,
		heroMedia,
		cardMedia,
		cardImageUrl,
		startingPrice,
		urlSlugs,
		cityCode: collectionPrimaryCityCode,
		cityDisplayName,
	} = collectionDetails;

	const imageURL =
		cardImageUrl || cardMedia?.url || heroImageUrl || heroMedia?.url;

	const isGlobalCity =
		currentCityCode === null || currentCityCode === GLOBAL_CITY_CODE;
	const cityCodeParam =
		appendCityCode &&
		!isGlobalCity &&
		currentCityCode !== collectionPrimaryCityCode
			? currentCityCode
			: '';

	const collectionUrlAsPath = urlSlugs
		? getCollectionUrlAsPath({
				urlSlugs,
				lang,
				city: cityCodeParam,
		  })
		: '';

	let collectionStartingPrice;

	if (startingPrice) {
		const { listingPrice, currency } = startingPrice;
		const currencyObj = currenciesMap?.[currency];
		collectionStartingPrice = getLocalisedPrice(
			listingPrice,
			currencyObj,
			lang,
		);
	}

	let subHeading =
		currentCityCode &&
		currentCityCode !== GLOBAL_CITY_CODE &&
		collectionStartingPrice
			? `${strings.FROM} ${collectionStartingPrice}`
			: cityDisplayName;

	if (isSearch)
		subHeading = collectionStartingPrice
			? `${strings.FROM} ${collectionStartingPrice}`
			: cityDisplayName;

	return {
		collectionUrlAsPath,
		collectionStartingPrice,
		subHeading,
		imageURL,
		collectionDetails,
		cityCode: collectionPrimaryCityCode,
	};
};

export const getCollectionCardDetailsV2 = ({
	collectionDetails,
	lang,
	currentCityCode,
	currenciesMap,
	isSearch,
}: {
	collectionDetails: TCollectionCardV2;
	lang: string;
	currentCityCode: string;
	appendCityCode: boolean;
	currenciesMap: Record<string, TCurrency>;
	isSearch: boolean;
}) => {
	const { medias, listingPrice, urlSlug, primaryCity } = collectionDetails;

	let collectionStartingPrice;

	if (listingPrice) {
		const currencyObj = currenciesMap?.[listingPrice.currency];
		collectionStartingPrice = getLocalisedPrice(
			listingPrice.listingPrice,
			currencyObj,
			lang,
		);
	}

	let subHeading =
		currentCityCode &&
		currentCityCode !== GLOBAL_CITY_CODE &&
		collectionStartingPrice
			? `${strings.FROM} ${collectionStartingPrice}`
			: primaryCity.displayName;

	if (isSearch) {
		subHeading = collectionStartingPrice
			? `${strings.FROM} ${collectionStartingPrice}`
			: primaryCity.displayName;
	}

	return {
		collectionUrlAsPath: urlSlug,
		collectionStartingPrice,
		subHeading,
		imageURL: medias?.[0]?.url,
		collectionDetails,
		cityCode: primaryCity.code,
	};
};
